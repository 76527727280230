<template>
	<v-container>
		<!-- <pre> {{ messages }}</pre> -->
		<v-data-table :headers="headers" :items="messages" disable-pagination hide-default-footer>
			<template v-slot:item.created_at="{ item }">
				<p>{{ item.created_at | formatDate }}</p>
			</template>

			<template v-slot:item.sent="{ item }">
				<v-icon v-if="item.sent" color="green">mdi-check</v-icon>
			</template>

			<template v-slot:item.delivered="{ item }">
				<v-icon v-if="item.delivered" color="green darken-2">mdi-check-all</v-icon>
			</template>

			<template v-slot:item.read="{ item }">
				<v-icon v-if="item.read" color="blue darken-2">mdi-check-all</v-icon>
			</template>
		</v-data-table>
	</v-container>
</template>
<script>
import SocketioService from "../services/socketioservice";
import api from "../services/api";

export default {
	data() {
		return {
			headers: [
                { text: "Inbox", value: "inbox.name"},
                { text: "Cliente_Meta", value: "customer_meta.name"},
                { text: "Cliente_Tel_Meta", value: "customer_meta.phone_number"},
				{ text: "Cliente", value: "customer.name" },
                { text: "Teléfono", value: "customer.tel"},
				{ text: "Num. Cliente", value: "customer.customer_no" },
                { text: "Mensaje", value: "content"},
				{ text: "Estado", value: "status" },
				{ text: "Fecha", value: "created_at" },
			],
			messages: [],
		};
	},
	created() {
        /*
		SocketioService.setupSocketConnection();

		SocketioService.socket.on("wam_status_change", (data) => {
			this.update_messages(data);
		});

        SocketioService.socket.on("new_message", (data) => {
            console.log(data)
        })
        */

		this.get_reminders();
	},
	beforeUnmount() {
		SocketioService.disconnect();
	},
	methods: {
		get_reminders() {
			api.get_messages().then((response) => (this.messages = response.data));
		},
		update_messages(data) {
			var message_to_update = this.messages.find((message) => message.wam_id === data.wam_id);

			if (message_to_update) {
				var status = Object.keys(data.status);
				var status_value = Object.values(data.status);
				console.log("updating wam_id " + message_to_update.wam_id + " with status " + status + " " + status_value);
				message_to_update[status] = status_value;
			} else {
				// this data is incomplete as it's coming from the webhook without customer data
				// this.messages.push(data)
				// one possible workaround might be to query the API for specific wam_id, for now I'll refresh everything
				this.get_reminders();
			}
		},
	},
};
</script>
