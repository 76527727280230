<template>
	<v-container fluid v-if="messages.length > 0">
		<v-row>
			<v-col cols="6">
				<v-card max-width="500">
					<v-card-text class="flex-grow-1 overflow-y-auto">
						<div v-for="(msg, i) in messages" :key="i">
							<div :class="{ 'd-flex flex-row-reverse': msg.message_type === 'incoming' }">
								<template>
									<v-chip :color="msg.message_type === 'incoming' ? 'primary' : ''" dark style="height: auto; white-space: normal" class="pa-4 mb-2">
										{{ msg.content }}
										<sub class="ml-2" style="font-size: 0.5rem"
											><span>{{ msg.inbox.name }}</span></sub
										>
										<sub class="ml-2" style="font-size: 0.5rem">{{ msg.created_at | formatDate }}</sub>
									</v-chip>
								</template>
							</div>
						</div>
					</v-card-text>
					<v-divider class="mx-4"></v-divider>
					<v-card-actions>
						<v-btn @click="get_messages()" color="primary" small rounded>Refrescar</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import api from "../services/api";

export default {
	props: ["customer_id"],
	data() {
		return {
			messages: [],
			messages_headers: [
				{ text: "phone", value: "phone" },
				{ text: "text", value: "text" },
				{ text: "outgoing", value: "outgoing" },
			],
		};
	},
	methods: {
		get_messages() {
			api.get_messages(this.customer_id).then((response) => (this.messages = response.data));
		},
	},
	mounted() {
		this.get_messages();
	},
};
</script>
