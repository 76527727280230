const axios = require('axios')
import router from '../router'
import TokenService from './token_service'

const url = process.env.VUE_APP_API_URL

axios.interceptors.request.use(
    (config) => {
        const token = TokenService.getLocalAccessToken();
        if (token) {
            // console.log('intercept: using access token ' + token.substring(token.length - 5) + ' for ' + config.url)
            config.headers["x-access-token"] = token
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
)

axios.interceptors.response.use((res) => {
    return res;
},
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            if (err.response.status === 401 && !originalConfig._retry) { // Access Token was expired
                originalConfig._retry = true
                // console.log('intercept: access token expired')

                const rs = await refresh_token(TokenService.getLocalRefreshToken())
                const { access_token } = rs.data;
                TokenService.updateLocalAccessToken(access_token)

                // console.log('intercept: got new token ' + access_token.substring(access_token.length - 5))

                originalConfig.headers["x-access-token"] = access_token
                return axios(originalConfig)
            }

            if (err.response.status === 403) {
                // console.log('intercept: 403, routing to login')
                router.push({ name: 'login' })
            }
        }

        return Promise.reject(err);
    }
)

const refresh_token = function (refresh_token) {
    return axios.post(url + '/api/auth/refresh_token', { refreshToken: refresh_token })
}

export default {
    get_customers(days_filter) {
        return axios.get(url + '/api/customers', {
            params: {
                days_filter: days_filter
            }
        })
    },
    get_inactive_customers() {
        return axios.get(url + '/api/customers/get_inactive')
    },
    get_customers_count() {
        return axios.get(url + '/api/customers/count')
    },
    get_demo_customers() {
        return axios.get(url + '/api/customers/get_demo')
    },
    get_customer(customer_id, days_filter) {
        return axios.get(url + '/api/customers', {
            params: {
                id: customer_id,
                days_filter: days_filter
            }
        })
    },
    update_customer(customer, nota1_updated) {
        return axios.post(url + '/api/customers/update_customer', {
            customer,
            nota1_updated
        })
            .then(response => response)
            .catch(error => error.response)
    },
    create_customer(new_record) {
        return axios.post(url + '/api/customers/new_customer', new_record)
    },
    get_referrals() {
        return axios.get(url + '/api/customers/referrals')
    },
    get_panels() {
        return axios.get(url + '/api/panels')
    },
    get_payments_aggregations(year, month) {
        return axios.get(url + '/api/payments/aggregations3', {
            params: {
                year: year,
                month: month
            }
        })
    },
    get_payment_methods() {
        return axios.get(url + '/api/payments/methods')
    },
    get_payments_aggregations2(year, month) {
        return axios.get(url + '/api/payments/aggregations5', {
            params: {
                year: year,
                month: month
            }
        })
    },    
    get_subscriptions_aggregations() {
        return axios.get(url + '/api/subscriptions/aggregation')
    },
    get_payment_details(year, month, day, channel_name, paid_via) {
        return axios.get(url + '/api/payments/payment_details', {
            params: {
                year: year,
                month: month,
                day: day,
                channel_name: channel_name,
                paid_via: paid_via
            }
        })
    },
    new_payment_method(new_method) {
        return axios.post(url + '/api/payments/new_method', {new_method: new_method})
    },
    get_panel(panel_id) {
        return axios.get(url + '/api/panels/' + panel_id)
    },
    update_panel(panel) {
        return axios.post(url + '/api/panels/', { panel })
    },
    make_payment(payment_info) {
        return axios.post(url + '/api/subscriptions/make_payment', {
            customerId: payment_info.customerId,
            user: payment_info.user,
            total_amount: payment_info.total_amount,
            voucher: payment_info.voucher,
            payment_date: payment_info.payment_date,
            paid_via: payment_info.paid_via,
            payments: payment_info.payments
        })
    },
    undo_payment(payment_info) {
        return axios.post(url + '/api/subscriptions/undo_payment', payment_info)
    },
    get_all_payments(year, month) {
        return axios.get(url + '/api/payments', {
            params: {
                year: year,
                month: month
            }
        })
    },
    get_customer_payments(customer_id) {
        return axios.get(url + '/api/payments', {
            params: {
                customerId: customer_id
            }
        })
    },
    get_devices() {
        return axios.get(url + '/api/devices')
    },
    get_device_types() {
        return axios.get(url + '/api/devices/types')
    },
    increment_stock(device_type) {
        return axios.post(url + '/api/devices/increment_stock', device_type)
    },
    new_device(new_device) {
        return axios.post(url + '/api/devices/new_device', { ...new_device })
    },
    new_device_type(new_device_type) {
        return axios.post(url + '/api/devices/new_type', { ...new_device_type })
    },
    update_device_type(device_type) {
        return axios.post(url + '/api/devices/update_type', { ...device_type })
    },
    get_notifications(userId) {
        return axios.get(url + '/api/notifications', {
            params: {
                userId: userId
            }
        })
    },
    mark_notification_read(notificationId, userId) {
        return axios.post(url + '/api/notifications/mark_read', {
            notificationId,
            userId
        })
    },
    get_channels() {
        return axios.get(url + '/api/channels')
    },
    update_channel(id, cost, name) {
        return axios.post(url + '/api/channels/update', {
            id, cost, name
        })
    },
    disable_channel(channelId) {
        return axios.post(url + '/api/channels/disable', { channelId: channelId })
    },
    enable_channel(channelId) {
        return axios.post(url + '/api/channels/enable', { channelId: channelId })
    },
    save_new_channel(new_channel) {
        new_channel.name = new_channel.name.replace(/\s/g, "_");
        return axios.post(url + '/api/channels/new', new_channel)
    },
    get_subscriptions(channelId) {
        return axios.get(url + '/api/subscriptions', {
            params: {
                channelId: channelId
            }
        })
    },
    save_new_subscriptions(new_data) {
        return axios.post(url + '/api/subscriptions/save_new', {
            params: {
                new_data
            }
        })
    },
    update_subscription(subscription) {
        return axios.post(url + '/api/subscriptions/update', subscription)
    },
    delete_subscription(subscription) {
        return axios.post(url + '/api/subscriptions/delete', { subscription })

    },
    login(user) {
        return axios.post(url + '/api/auth/login', { user })
    },
    logout() {
        return axios.post(url + '/api/auth/logout')
    },
    get_expenses(year, month) {
        return axios.get(url + '/api/expenses', {
            params: {
                year: year,
                month: month
            }
        })
    },
    get_expense_details(year, month, day) {
        return axios.get(url + '/api/expenses/expenses_details', {
            params: {
                year: year,
                month: month,
                day: day
            }
        })
    },
    new_expense(new_expense) {
        return axios.post(url + '/api/expenses/new_expense', { ...new_expense })
    },
    update_expense(expense) {
        return axios.post(url + '/api/expenses/update_expense', { ...expense })
    },
    delete_expense(expense) {
        return axios.post(url + '/api/expenses/delete_expense', { ...expense})
    },
    get_reminders() {
        return axios.get(url + '/api/messages/reminders')
    },
    get_messages(customer_id) {
        return axios.get(url + '/api/messages', {
            params: {
                customer_id: customer_id
            }
        })
    }
}