<template>
	<v-container>
		<v-card>
			<v-card-title>
				<v-row justify="center">Egresos</v-row>
			</v-card-title>
			<v-card-text>
				<v-form v-model="isFormValid">
					<v-row justify="center">
						<v-col>
							<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="new_expense.expense_date" label="Fecha de pago" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="new_expense.expense_date" @input="menu = false" locale="es"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col>
							<v-text-field label="Comprobante" v-model="new_expense.voucher" />
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-select :rules="[(v) => !!v || 'Tipo de pago es requerido']" label="Tipo" :items="expense_types" v-model="new_expense.expense_type" />
						</v-col>
						<v-col>
							<v-text-field label="Proveedor" v-model="new_expense.provider" />
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-text-field label="Detalle" v-model="new_expense.expense_details" />
						</v-col>
						<v-col>
							<v-text-field label="Monto" type="number" prefix="$" v-model="new_expense.amount" />
						</v-col>
					</v-row>
					<v-row justify="center" no-gutters>
						<v-btn color="primary" :disabled="!isFormValid" @click="save_expense()">Guardar</v-btn>
					</v-row>
				</v-form>
			</v-card-text>
		</v-card>

		<v-container>
			<v-row align="center">
				<v-col cols="1"><v-text-field field v-model="expenses_year" type="number" label="Año" /></v-col>
				<v-col cols="1"><v-text-field field v-model="expenses_month" type="number" label="Mes" /></v-col>
				<v-col cols="2"><v-btn @click="get_expenses()" color="primary" elevation="2" small>Ir</v-btn></v-col>
			</v-row>
		</v-container>

		<v-simple-table>
			<template v-slot:default>
				<thead>
					<tr>
						<th v-for="header in expenses_headers" :key="header">
							<p v-if="header == 'Total'" class="total">{{ header }}</p>
							<p v-else-if="header == 'Fecha'" class="centered">{{ header }}</p>
							<p v-else>{{ header }}</p>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in expenses.expenses" :key="item.expense_date">
						<td>
							<v-btn color="#F9F7F7" elevation="2" class="font-weight-bold" rounded small @click="get_expense_details(item['expense_day'])">{{
								item["expense_day"]
							}}</v-btn>
						</td>
						<td>{{ item["Internet"] }}</td>
						<td>{{ item["Teléfono"] }}</td>
						<td>{{ item["Subscripción recurrente"] }}</td>
						<td>{{ item["Pago EFS"] }}</td>
						<td>{{ item["Pago RSP"] }}</td>
						<td>{{ item["Envíos"] }}</td>
						<td>{{ item["Almuerzos JD"] }}</td>
                        <td>{{ item["Créditos"] }}</td>
						<td>{{ item["Otro"] }}</td>
						<td class="total">{{ item["Total"] }}</td>
					</tr>

                    <tr class="footer">
                        <td></td>
                        <td v-for="(value, name) in expenses.totals" :key="name">
                            ${{ value }}
                        </td>
                        
                    </tr>

				</tbody>
			</template>
		</v-simple-table>

		<v-dialog v-model="expenses_details_dialog" @input="close">
			<v-card>
				<v-card-title>Detalle de gastos</v-card-title>
				<v-card-text>
					<v-data-table :headers="expenses_details_headers" :items="expense_details">
						<template v-slot:item.expense_date="{ item }">
							{{ item.expense_date | formatDate }}
						</template>
						<template v-slot:item.edit="{ item }">
							<v-icon small @click="edit_expense(item)" class="mr-2"> mdi-pencil </v-icon>
							<v-icon small @click="delete_expense(item)"> mdi-delete </v-icon>
						</template>
					</v-data-table>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="expense_edit_dialog" @input="close" max-width="600">
			<v-card>
				<v-card-title>Editar Gasto</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								{{ expense_to_edit.expense_date }}
								<v-menu v-model="expense_date_menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="expense_to_edit.expense_date" label="Fecha" readonly v-bind="attrs" v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="expense_to_edit.expense_date" @input="menu = false" locale="es"></v-date-picker>
								</v-menu>
							</v-col>
							<v-col>
								<v-text-field v-model="expense_to_edit.voucher" label="Comprobante" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-select :rules="[(v) => !!v || 'Tipo de pago es requerido']" label="Tipo" :items="expense_types" v-model="expense_to_edit.expense_type" />
							</v-col>
							<v-col>
								<v-text-field v-model="expense_to_edit.provider" label="Proveedor" />
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea v-model="expense_to_edit.expense_details" label="Detalles" />
							</v-col>
							<v-col>
								<v-text-field v-model="expense_to_edit.amount" label="Monto" type="number" />
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="close"> Cancelar </v-btn>
					<v-btn color="success" @click="update_expense(item)"> Guardar </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="expense_delete_dialog" @input="close" max-width="500">
			<v-card>
				<v-card-title>Confirmar eliminación de gasto</v-card-title>
				<v-card-text> ¿Está seguro que desea eliminar este gasto? </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" @click="close">Cancelar</v-btn>
					<v-btn color="success" @click="confirm_delete_expense()"> Borrar </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="expense_saved" top>
			{{ expense_saved_ok }}

			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>
<script>
import api from "../services/api";
import moment from "moment";

export default {
	data() {
		return {
			expenses_year: new Date().getFullYear(),
			expenses_month: new Date().getMonth() + 1,
			isFormValid: false,
			menu: false,
			expense_saved_ok: "",
			expense_saved: false,
			new_expense: {
				expense_date: moment().toISOString(true).split("T")[0],
				expense_type: "",
				voucher: "",
				provider: "",
				expense_details: "",
				amount: 0,
			},
			expense_types: ["Internet", "Teléfono", "Subscripción recurrente", "Pago EFS", "Pago RSP", "Envíos", "Almuerzos JD", "Créditos", "Otro"],
			expenses: [],
			expense_details: [],
			expenses_details_dialog: false,
			expenses_headers: ["Fecha", "Internet", "Teléfono", "Subscripción", "Pago EFS", "Pago RSP", "Envíos", "Almuerzos JD", "Créditos", "Otro", "Total"],
			expenses_details_headers: [
				{ text: "Fecha", value: "expense_date" },
				{ text: "Comprobante", value: "voucher" },
				{ text: "Tipo", value: "expense_type" },
				{ text: "Proveedor", value: "provider" },
				{ text: "Detalles", value: "expense_details" },
				{ text: "Monto", value: "amount" },
				{ text: "Editar", value: "edit" },
			],
			expense_edit_dialog: false,
			expense_to_edit: {},
			expense_to_delete: {},
			expense_date_menu: false,
			expense_delete_dialog: false,
		};
	},
	methods: {
		edit_expense(expense) {
			this.expense_to_edit = expense;
			this.expense_to_edit.expense_date = this.expense_to_edit.expense_date.split("T")[0];
			this.expenses_details_dialog = false;
			this.expense_edit_dialog = true;
		},
		close() {
			this.expense_details = [];
			this.expense_edit_dialog = false;
			this.expense_to_edit = {};
			this.expense_delete_dialog = false;
			this.expenses_details_dialog = false;
		},
		get_expense_details(expense_day) {
			api.get_expense_details(this.expenses_year, this.expenses_month, expense_day).then((response) => {
				this.expense_details = response.data;
				this.expenses_details_dialog = true;
			});
		},
		get_expenses() {
			api.get_expenses(this.expenses_year, this.expenses_month).then((response) => (this.expenses = response.data));
		},
		save_expense() {
			api.new_expense(this.new_expense).then((response) => {
				if (response.status === 200) {

					this.new_expense = {
						expense_date: moment().toISOString(true).split("T")[0],
						expense_type: "Internet",
						voucher: "",
						provider: "",
						expense_details: "",
						amount: 0,
					};

					this.saved_ok();                    
				}
			});
		},
		update_expense() {
			api.update_expense(this.expense_to_edit).then((response) => {
				if (response.status === 200) {
					this.close();
					this.saved_ok();
				}
			});
		},
		delete_expense(expense) {
			this.expense_to_delete = expense;
			this.expense_delete_dialog = true;
		},
		confirm_delete_expense() {
			api.delete_expense(this.expense_to_delete).then((response) => {
				if (response.status === 200) {
					this.close();
					this.saved_ok();
				}
			});
		},
		saved_ok() {
			this.expense_saved_ok = "Gasto guardado con éxito";
			this.expense_saved = true;
			this.get_expenses();
		},
	},
	mounted() {
		this.get_expenses();
	},
};
</script>
<style scoped>
.centered {
	text-align: left;
}

.footer {
	font-weight: bolder;
	background-color: antiquewhite;
}
.total {
	/* background-color: blanchedalmond !important; */
	/* text-align: center; */
	color: black;
	font-weight: bolder;
	overflow-wrap: none;
}
</style>