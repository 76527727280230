var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.messages,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('p',[_vm._v(_vm._s(_vm._f("formatDate")(item.created_at)))])]}},{key:"item.sent",fn:function(ref){
var item = ref.item;
return [(item.sent)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.delivered",fn:function(ref){
var item = ref.item;
return [(item.delivered)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check-all")]):_vm._e()]}},{key:"item.read",fn:function(ref){
var item = ref.item;
return [(item.read)?_c('v-icon',{attrs:{"color":"blue darken-2"}},[_vm._v("mdi-check-all")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }