import Vue       from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home';
import Home2 from '../components/Home2';
import Customers from '../components/Customers';
import Customer from '../components/Customer';
import NewCustomer from '../components/NewCustomer';
import Devices from '../components/Devices';
import NewDevice from '../components/NewDevice';
import LoginForm from '../components/login/LoginForm';
import LogOut from '../components/login/LogOut';
import PaymentsDashboard from '../components/PaymentsDashboard.vue'
import InactiveCustomers from '../components/InactiveCustomers.vue'
import Referrals from '../components/Referrals.vue';
import ChannelAdmin from '../components/ChannelAdminComponent.vue';
import Expenses from '../components/Expenses.vue';
import MessagesDashboard from '../components/MessagesDashboard.vue';
import Chat from '../components/Chat.vue';
import DemoCustomers from '../components/DemoCustomers.vue'
import store from '../store';

Vue.use(VueRouter)

const router = new VueRouter({

mode: 'history',
routes: [
    {
        path: '/login',
        name: 'login',
        component: LoginForm,
        meta: {
            title: 'Ingresar'
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogOut,
        meta: {
            title: 'Salir'
        }
    },
    {
        path: '/home', 
        name: 'home', 
        component: Home,
        meta: {
            title: 'Inicio'
        }        
    },
    {
        path: '/home2', 
        name: 'home2', 
        component: Home2,
        meta: {
            title: 'Inicio - BORRAR'
        }        
    },
    { 
        path: '/customers', 
        name: 'customers', 
        component: Customers,
        props: true,
        meta: {
            title: 'Clientes'
        },    
    },
    {
        path: '/new_customer',
        name: 'new_customer',
        component: NewCustomer,
        meta: {
            title: 'Crear Cliente'
        }        
    },
    {
        path: '/inactive_customers',
        name: 'inactive_customers',
        component: InactiveCustomers,
        meta: {
            title: 'Clientes Inactivos'
        }
    },
    {
        path: '/referrals',
        name: 'referrals',
        component: Referrals,
        meta: {
            title: 'Clientes referidos'
        }
    },
    {
        path: '/demo_customers',
        name: 'demos',
        component: DemoCustomers,
        meta: {
            title: 'Clientes Demo'
        }
    },
    { 
        path: '/devices', 
        name: 'devices', 
        component: Devices,
        meta: {
            title: 'Dispositivos'
        }
    },
    { 
        path: '/expenses', 
        name: 'expenses', 
        component: Expenses,
        meta: {
            title: 'Egresos'
        }
    },    
    {
        path: '/new_device',
        name: 'new_device',
        component: NewDevice,
        meta: {
            title: 'Nuevo Dispositivo'
        }
    },
    { 
        path: '/customer/', 
        name: 'customer', 
        component: Customer,
        meta: {
            title: 'Cliente'
        }
    },
    {
        path: '/channel_admin',
        name: 'channel_admin',
        component: ChannelAdmin,
        meta: {
            title: 'Administración de canales'
        }
    },
    {
        path: '/payments',
        name: 'payments_dashboard',
        component: PaymentsDashboard,
        meta: {
            title: 'Pagos'
        }
    },
    {
        path: '/messages',
        name: 'messages_dashboard',
        component: MessagesDashboard,
        meta: {
            title: 'Mensajería'
        }
    },
    {
        path: '/chat',
        name: 'chat',
        component: Chat,
        meta: {
            title: 'Chat'
        }
    },
    { 
        path: '*',
        redirect: '/home'
    },
]

})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    if(!store.getters.user.username && to.fullPath != '/login') {
        next('/login')
    } else {
        next()
    }
})

export default router