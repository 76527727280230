<template>
	<v-container fluid>
		<v-card>
			<v-card-title>
				<!-- Buscar
        <v-spacer></v-spacer> -->
				<v-row no-gutters>
					<v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" single-line hide-details></v-text-field>

					<v-spacer></v-spacer>

					<v-col cols="2">
						<v-select label="Días" :items="days_range" v-model="days_filter">Días</v-select>
					</v-col>

					<v-spacer></v-spacer>

					<v-checkbox label="Incluir cargo automático" v-model="include_auto_charge">Cargo</v-checkbox>
				</v-row>
			</v-card-title>

			<v-data-table
				class="table is-striped is-size-6"
				:headers="columns"
				:items="filtered"
				:search="search"
				disable-pagination
				hide-default-footer
				sort-by="customer_no"
				items-per-page.sync="15"
				page.sync="1"
				:custom-filter="customSearch"
			>

                <template v-slot:item.name = "{ item }">
                    <v-btn text color="info" @click="navigate(item.id)">{{item.name}}</v-btn>
                </template>

				<template v-slot:item.subscriptions="{ item }">
					<v-row no-gutters v-for="subscription in item.subscriptions" :key="subscription.id">
						<v-col cols="8" sm="5" md="3"> {{ subscription.channel.name.replace("_", " ") }}</v-col>
						<v-col cols="5" md="4" sm="5">
                            <v-badge :color="get_tag(subscription.status)" dot inline>{{ subscription.days_left }} días</v-badge>
                        </v-col>
					</v-row>
				</template>

				<template v-slot:item.auto_charge="{ item }">
					<p>{{ item.auto_charge | booleanToString }}</p>
				</template>

				<!-- <template v-slot:item.details="{ item }">
					<v-btn class="mx-2" fab dark small color="info" @click="navigate(item.id)">
						<v-icon dark> mdi-magnify </v-icon>
					</v-btn>
				</template> -->
			</v-data-table>
		</v-card>
	</v-container>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";

export default {
	data() {
		return {
			days_range: [...Array(15 - 1 + 1).keys()].map((x) => x + 1),
			search: "",
			columns: [
				{ text: "Num. Cliente", value: "customer_no", width: "100" },
				{ text: "Nombre", value: "name", searchable: true, width: "300" },
                { text: "Precio Regular", value: "regular_payment" },
				{ text: "Cargo automático", value: "auto_charge" },
				{ text: "Subscripciones", value: "subscriptions" },
				// { text: "Detalles", value: "details" },
			],
			channels: [],
		};
	},
	computed: {
		...mapState({
			customers: (state) => state.customers,
			status_shown: (state) => state.status_shown,
		}),
		include_auto_charge: {
			get() {
				return this.$store.getters.include_auto_charge;
			},
			set() {
				this.$store.dispatch("toggle_auto_charge");
			},
		},
		days_filter: {
			get() {
				return this.$store.getters.days_filter;
			},
			set(newValue) {
				this.$store.dispatch("change_days_filter", newValue);
			},
		},
		current_status() {
			var current_status = "";
			if (Array.isArray(this.status_shown)) {
				current_status = "todos";
			} else {
				current_status = this.status_shown;
			}

			return current_status;
		},
		filtered() {
			let customers = this.customers;

			if (this.include_auto_charge === false) {
				customers = this.customers.filter((customer) => {
					return customer.auto_charge === false;
				});
			}

			if (Array.isArray(this.status_shown)) {
				return customers;
			} else {
				return customers
					.map((customer) => ({
						...customer,
						subscriptions: customer.subscriptions.filter((subscription) => {
							return this.status_shown == subscription.status;
						}),
					}))
					.filter((customer) => customer.subscriptions.length > 0); // esto quiebra los filtros del semaforo si se quita
			}
		},
	},
	methods: {
        subscription_status(status) {
            return status.substring(0,0)
        },        
		customSearch(value, search, item) {
            const item2 = JSON.parse(JSON.stringify(item))
			return (
				Object.values(item2).toString().toLowerCase().includes(search) ||
				Object.values(
					item2.subscriptions.map((i) => {
						delete i.channel;
						return Object.values(i);
					})
				).flat().toString().includes(search)
			)
		},
		get_tag(status) {
			if (status == "activo") {
				return "success";
			} else if (status === "recordar") {
				return "warning";
			} else if (status === "expirado") {
				return "error";
			}
		},
		payment_due(date, credits) {
			return moment(date).startOf("day").add(credits, "months").format("DD-MMM-YY");
		},
		navigate(id) {
			this.$router.push({ name: "customer", query: { id: id } });
		},
	},
	mounted() {},
};
</script>
