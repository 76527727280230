<template>
  <v-container>
    <v-data-table :items="payment_records" :headers="columns">

      <template v-slot:item.credits="{ item }">
        <div
          v-for="(payment, index) in item.payment_details"
          :key="index"
          class="d-flex justify-left"
        >
          {{ payment.credits }}
        </div>
      </template>


      <template v-slot:item.payment_date="{ item }">
        {{ item.payment_date | formatDate }}
      </template>

      <template v-slot:item.payment_details="{ item }">
        <div
          v-for="(payment, index) in item.payment_details"
          :key="index"
          class="d-flex justify-left"
        >
          {{ payment.channel_name.replace(/_/g, " ") }}
        </div>
      </template>



      <template v-slot:item.undo_payment="{ item }">
        <v-btn
            v-if="item.id == Math.max.apply(null, payment_records.map((payment => payment.id)))"
            fab 
            small 
            color="info" 
            @click="undo_payment(item)"
            :disabled="item.reversed || item.payment_type === 'reversion' || item.payment_type === 'nuevo cliente' " 
          ><v-icon>mdi-undo</v-icon></v-btn
        >
      </template>

    </v-data-table>
  </v-container>
</template>

<script>
import api from "../services/api";

export default {
  props: ["customer"],
  data() {
    return {
      columns: [
        { text: "Créditos", value: "credits" },
        { text: "Monto", value: "amount" },
        { text: "Subscripciones", value: "payment_details"},
        { text: "Fecha de Pago", value: "payment_date" },
        { text: "Tipo de Pago", value: "payment_type" },
        { text: "Usuario", value: "user" },
        { text: "Comprobante", value: "voucher"},
        { text: "Deshacer", value: "undo_payment" },
      ],
      payment_records: [],
    };
  },
  methods: {
    get_payments() {
        api.get_customer_payments(this.customer.id).then((response) => {
        this.payment_records = response.data;
        })
    },
    undo_payment(payment) {
      api.undo_payment(payment).then((response) => {
        if (response.status === 200) {
            this.get_payments()
            this.$emit("get_customer")
            this.$store.dispatch("get_customers")
        }
      })
    },
  },
  mounted() {
    console.log("geting payments for customer " + this.customer.id);
    this.get_payments()
  },
};
</script>

<style>
</style>