<template>
	<v-layout id="inspire">
		<v-overlay :value="drawer" dark v-if="user.username"> </v-overlay>
		<v-navigation-drawer v-model="drawer" app clipped hide-overlay disable-resize-watcher v-if="user.username">
			<v-list dense>
				<v-list-item to="/home">
					<v-list-item-action>
						<v-icon>mdi-home</v-icon>
					</v-list-item-action>
					<v-list-item-content>Inicio</v-list-item-content>
				</v-list-item>
				<v-list-group prepend-icon="mdi-emoticon" no-action>
					<template v-slot:activator>
						<v-list-item-content> Clientes </v-list-item-content>
					</template>
					<v-list-item to="/customers">
						<v-list-item-title>Clientes Activos</v-list-item-title>
					</v-list-item>
					<v-list-item to="/inactive_customers"> <v-list-item-title>Clientes Inactivos</v-list-item-title> </v-list-item>
					<v-list-item to="/new_customer">
						<v-list-item-title>Agregar Cliente</v-list-item-title>
					</v-list-item>
					<v-list-item to="/referrals">
						<v-list-item-title>Referidos</v-list-item-title>
					</v-list-item>
					<v-list-item to="/demo_customers">
						<v-list-item-title>Demos</v-list-item-title>
					</v-list-item>
				</v-list-group>
				<v-list-item to="/payments">
					<v-list-item-action>
						<v-icon>mdi-cash-multiple</v-icon>
					</v-list-item-action>
					<v-list-item-content>Pagos</v-list-item-content>
				</v-list-item>
				<v-list-group prepend-icon="mdi-wrench" no-action>
					<template v-slot:activator>
						<v-list-item-content>Admin.</v-list-item-content>
					</template>
					<v-list-item to="/channel_admin">
						<v-list-item-title>Paquetes</v-list-item-title>
					</v-list-item>
					<v-list-item to="/devices">
						<v-list-item-title>Dispositivos</v-list-item-title>
					</v-list-item>
					<v-list-item to="/expenses">
						<v-list-item-title>Egresos</v-list-item-title>
					</v-list-item>
				</v-list-group>
				<v-list-item to="/logout">
					<v-list-item-action>
						<v-icon>mdi-exit-run</v-icon>
					</v-list-item-action>
					<v-list-item-content>Salir</v-list-item-content>
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
		<v-toolbar dark prominent>
			<v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
			<!-- <v-toolbar-title class="hidden-sm-and-down text-h6">Stream TV</v-toolbar-title> -->
			<!-- <v-col cols="1" class="flex-grow-0 flex-shrink-0"></v-col> -->
			<!-- blank space between title and menus -->
			<template v-if="user.username">
				<v-toolbar-items class="hidden-sm-and-down">
					<v-btn text small to="/home">Inicio</v-btn>
					<v-menu offset-y close-on-click dark>
						<template v-slot:activator="{ on, attrs }">
							<v-btn text small v-bind="attrs" v-on="on">Clientes <v-icon>mdi-menu-down</v-icon> </v-btn>
						</template>
						<v-list>
							<v-list-item>
								<v-btn small to="/customers" text>Clientes Activos</v-btn>
							</v-list-item>
							<v-list-item>
								<v-btn small to="/inactive_customers" text>Clientes Inactivos</v-btn>
							</v-list-item>
							<v-list-item>
								<v-btn small to="/new_customer" text>Agregar Cliente</v-btn>
							</v-list-item>
							<v-list-item>
								<v-btn small to="/referrals" text>Referidos</v-btn>
							</v-list-item>
							<v-list-item>
								<v-btn small to="/demo_customers" text>Demos</v-btn>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-btn text small to="/payments">Pagos</v-btn>
					<v-menu offset-y close-on-click dark>
						<template v-slot:activator="{ on, attrs }">
							<v-btn text small v-bind="attrs" v-on="on">Admin. <v-icon>mdi-menu-down</v-icon> </v-btn>
						</template>
						<v-list>
							<v-list-item>
								<v-btn small to="/channel_admin" text>Paquetes</v-btn>
							</v-list-item>
							<v-list-item>
								<v-btn small to="/devices" text>Dispositivos</v-btn>
							</v-list-item>
							<v-list-item>
								<v-btn small to="/expenses" text>Egresos</v-btn>
							</v-list-item>
						</v-list>
					</v-menu>
					<v-btn text small to="/logout">Salir</v-btn>
				</v-toolbar-items>

				<!-- <v-col cols="1" class="flex-grow-0 flex-shrink-0 hidden-md-and-down"></v-col> -->

				<v-col cols="1" align-self="center">
					<v-row no-gutters>
						<span class="text-subtitle-2">Clientes: {{ customers_count.customers }}</span>
					</v-row>
					<v-row no-gutters>
						<span class="text-subtitle-2">Demos: {{ customers_count.demos }}</span>
					</v-row>
				</v-col>

				<v-col cols="1" align-self="center">
					<v-row no-gutters>
						<a :href="excel_link" target="_blank" v-if="excel_link">
							<v-icon medium color="green"> mdi-file-excel </v-icon>
						</a>
					</v-row>
					<v-row no-gutters>
						<a :href="word_link" target="_blank" v-if="word_link">
							<v-icon medium color="blue"> mdi-file-word </v-icon>
						</a>
					</v-row>
				</v-col>

				<v-col lg="5" md="8" sm="4" align-self="center">
					<v-chip-group column>
						<v-chip
							v-for="(count, status) in status_counts"
							:key="status"
							:color="get_tag(status)"
							:class="isActive(status)"
							small
							pill
							@click="set_status(status)"
						>
							<span>{{ status }} {{ count }}</span>
						</v-chip>
					</v-chip-group>
				</v-col>

				<!-- <v-col cols="1" class="flex-grow-0 flex-shrink-0 hidden-md-and-down"></v-col> -->

				<v-col align-self="center" lg="1" sm="1" md="1" class="hidden-md-and-down text-subtitle-1">
					<v-row>
						{{ get_date | formatDate }}
					</v-row>
					<Clock />
				</v-col>

				<v-col align-self="center">
					<v-btn text @click="show_notifications" :disabled="notifications.length === 0">
						<v-badge v-if="notifications.length > 0" :content="notifications.length" :value="notifications" color="green" overlap>
							<v-icon large> mdi-bell </v-icon>
						</v-badge>
					</v-btn>
				</v-col>
			</template>

			<v-dialog v-model="notification_toggle" max-width="500">
				<v-sheet v-for="notification in notifications" :key="notification.id">
					<v-card elevation="0">
						<v-card-title> Nota1 cliente {{ notification.body.customer_no }} actualizada</v-card-title>
						<v-card-text>
							<v-row>
								<v-col> Nota1: {{ notification.body.nota1 }} </v-col>
								<v-col cols="1">
									<v-btn icon color="primary" @click="navigate(notification.body.customer_id)"><v-icon>mdi-account-search</v-icon></v-btn>
								</v-col>
								<v-col cols="1">
									<v-btn icon color="primary" @click="mark_read(notification.id)"><v-icon>mdi-delete</v-icon></v-btn>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-sheet>
			</v-dialog>
		</v-toolbar>
	</v-layout>
</template>
<script>
import { mapState } from "vuex";
import { version } from "../../package";
import moment from "moment";
import Clock from "./Clock.vue";
import api from "../services/api";

export default {
	components: { Clock },
	data() {
		return {
			interval: null,
			time: null,
			drawer: false,
			toggleMenu: false,
			appVersion: version,
			notification_toggle: false,
			customers_count: {
				customers: 0,
				demos: 0,
			},
			// status_counts: []
		};
	},
	computed: {
		...mapState({
			user: (state) => state.user,
			customers: (state) => state.customers,
			status_shown: (state) => state.status_shown,
			status_counts: (state) => state.status_counts,
		}),
		excel_link() {
			return process.env.VUE_APP_EXCEL_LINK || null;
		},
		word_link() {
			return process.env.VUE_APP_WORD_LINK || null;
		},
		notifications() {
			return this.$store.getters.notifications;
		},
		get_date() {
			return moment();
		},
		days_filter() {
			return this.$store.getters.days_filter;
		},
		showMenu() {
			return this.toggleMenu || this.$vuetify.breakpoint.mdAndUp;
		},
	},
	methods: {
		// get_status_counts() {
		//     console.log('get status counts')
		// 	var counts = { todos: 0, recordar: 0, activo: 0, expirado: 0 };
		// 	this.customers.flatMap((customer) =>
		// 		customer.subscriptions.forEach((subscription) => {
		// 			counts["todos"] += 1;
		// 			if (counts[subscription.status]) {
		// 				counts[subscription.status] += 1;
		// 			} else {
		// 				counts[subscription.status] = 1;
		// 			}
		// 		})
		// 	);
		// 	return counts;
		// },
		mark_read(notification_id) {
			const index = this.notifications.indexOf(this.notifications.find((n) => n.id === notification_id));
			this.notifications.splice(index, 1);

			api.mark_notification_read(notification_id, this.user.id).then((response) => {
				if (response.status === 200) {
					this.$store.dispatch("get_notifications", this.user.id);
					this.notification_toggle = false;
				}
			});
		},
		isActive(status) {
			if (Array.isArray(this.status_shown)) return "active";
			if (this.status_shown === status) {
				return "active";
			}
			return "short";
		},
		get_tag(status) {
			if (status === this.status_shown) return "cyan";
			if (status == "activo") return "success";
			if (status == "recordar") return "warning";
			if (status == "expirado") return "error";
		},
		set_status(status) {
			this.$router.push({ name: "customers" });
			if (status == "todos") {
				return this.$store.dispatch("set_status_shown", ["activo", "recordar", "expirado"]);
			}
			this.$store.dispatch("set_status_shown", status);
		},
		show_notifications() {
			this.notification_toggle = true;
		},
		navigate(id) {
			this.notification_toggle = false;

			this.$router.push({ name: "customer", query: { id: id } });
		},
	},
	mounted() {
		this.$store.dispatch("change_days_filter", 3);
		this.$store.dispatch("get_customers", this.days_filter);
		this.$store.dispatch("get_all_channels");
		// this.status_counts = this.get_status_counts()
		this.$store.dispatch("get_status_counts");
        this.$store.dispatch("get_payment_methods");
		api.get_customers_count().then((response) => (this.customers_count = response.data));
	},
};
</script>
<style scoped>
.short {
	height: 25px;
}
.short span {
	font-size: small;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.active {
	height: 30px;
}
.active span {
	font-size: medium;
}
</style>
