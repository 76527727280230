<template>
    <v-row>
        {{ time }}
    </v-row>
</template>
<script>
export default {
  data() {
    return {
      time: null,
    };
  },
  created() {
    this.interval = setInterval(() => {
      this.time = Intl.DateTimeFormat(navigator.language, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      }).format();
    }, 1000);
  },
};
</script>

<style>
</style>