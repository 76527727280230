<template>
	<v-container>
		<v-row justify="center">
			<v-col lg="4" align="center">
				<v-container>
					<v-btn color="info" @click="make_payment" large>Pagar</v-btn>
				</v-container>
			</v-col>
		</v-row>

        <v-container>
		<v-data-table :items="customer.subscriptions" :headers="subscriptions_columns" disable-pagination hide-default-footer>
			<template v-slot:item.next_payment_date="{ item }">
				<p>{{ item.next_payment_date | formatDate }}</p>
			</template>

            <template v-slot:item.channel.name="{ item }">
                {{ item.channel.name.replace("_", " ") }}
            </template>

			<template v-slot:item.contract_date="{ item }">
				<p>{{ item.contract_date | formatDate }}</p>
			</template>

			<template v-slot:item.status="{ item }">
				<v-chip class="ma-2" :color="get_tag(item.status)" text-color="black" x-small>
					{{ item.status }}
				</v-chip>
			</template>

			<template v-slot:item.edit="{ item }">
				<v-btn icon color="info" small rounded @click="edit_subscription(item)">
					<v-icon>mdi-pencil</v-icon>
				</v-btn>
			</template>

			<template v-slot:item.delete="{ item }">
				<v-btn icon color="error" small rounded @click="confirm_delete_subscription(item)">
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</v-data-table>
        </v-container>

        <v-container><v-divider/></v-container>
        

        <v-container>
            <v-row justify="center">
                <p class="title">Dispositivos</p>
            </v-row>
            <v-data-table :items="customer.devices" :headers="devices_columns" disable-pagination hide-default-footer />
        </v-container>

		<v-container v-if="add_new">
			<v-sheet elevation="1" outlined tile>
				<v-container>
					<v-row>
						<v-col cols="auto" v-for="(subscription, index) in new_subscriptions" :key="index">
							<NewSubscriptionComponent v-if="add_new" :key="index" :index="index" :subscriptions="new_subscriptions" :subscription="subscription" />
						</v-col>
					</v-row>
				</v-container>
				<v-container>
					<v-row justify="center">
						<v-col cols="auto">
							<v-select label="Tipo de pago" :items="payment_types" item-text="name" item-value="id" v-model="paid_via" />
						</v-col>
						<v-col cols="auto">
							<v-text-field v-model="voucher" label="Comprobante" />
						</v-col>
						<v-col>
							<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
								<template v-slot:activator="{ on, attrs }">
									<v-text-field v-model="payment_date" label="Fecha de pago" readonly v-bind="attrs" v-on="on"></v-text-field>
								</template>
								<v-date-picker v-model="payment_date" @input="menu = false" locale="es"></v-date-picker>
							</v-menu>
						</v-col>
						<v-col cols="auto">
							<v-text-field v-model="total_amount" :hint="'$' + suggested_total_amount" persistent-hint type="number" prefix="$"></v-text-field>
						</v-col>
					</v-row>
					<v-container>
						<v-row justify="center">
							<v-btn class="mx-2" color="warning" @click="save_new_subs()" small>Guardar</v-btn>
							<v-btn class="mx-2" small color="error" @click="cancel_add_subscription()">Cancelar</v-btn>
						</v-row>
					</v-container>
				</v-container>
			</v-sheet>
		</v-container>

		<v-container>
			<v-row justify="center">
				<v-col cols="8">
					<v-row>
						<v-col align="center">
							<v-btn class="mx-2" color="warning" @click="add_subscription()" small>Agregar Subscripción</v-btn>
						</v-col>
						<v-col align="center">
							<v-btn class="mx-2" color="primary" @click="add_device()" small>Agregar Dispositivo</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>

		<v-dialog v-model="edit" @input="close" max-width="500">
			<template>
				<Sub_Edit v-if="edit" :subscription="subscription_to_edit" @close="close" />
			</template>
		</v-dialog>

		<v-dialog v-model="make_payment_dialog" @input="close">
			<template>
				<MakePaymentComponent v-if="make_payment_dialog" :customerId="customer.id" :subscriptions="customer.subscriptions" @saved_ok="saved_ok" />
			</template>
		</v-dialog>

		<v-dialog v-model="delete_dialog" persistent max-width="290">
			<v-card>
				<v-card-title class="text-h6"> Confirmar </v-card-title>
				<v-card-text>Confirmar que quiere eliminar esta subscripción </v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" small @click="delete_dialog = false"> Cancelar </v-btn>
					<v-btn color="success" small @click="delete_subscription"> Eliminar </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="new_device_dialog">
			<v-sheet>
				<v-card>
					<v-card-title>Agregar Dispositivo</v-card-title>
					<v-card-text>
                        <v-form v-model="new_device_form_valid">
						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-select
									label="Tipo de dispositivo"
									:items="device_types"
									item-text="type"
									item-value="id"
									v-model="new_device.type"
									:rules="[(v) => !!v || 'Tipo es requerido']"
								/>
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field label="Serial" v-model="new_device.serial" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<p class="title">Pago</p>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field v-model="new_device.quantity" label="Cantidad" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field v-model="new_device.voucher" label="Comprobante" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-select label="Tipo de pago" :items="payment_types" item-text="name" item-value="id" v-model="new_device.paid_via" :rules="[(v) => !!v || 'Tipo es requerido']" />
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="new_device.payment_date" label="Fecha de pago" readonly v-bind="attrs" v-on="on"></v-text-field>
									</template>
									<v-date-picker v-model="new_device.payment_date" @input="menu = false" locale="es"></v-date-picker>
								</v-menu>
							</v-col>
						</v-row>

						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-text-field
									label="Monto"
									v-model.number="new_device.amount"
									type="number"
									prefix="$"
									:hint="'$' + String(suggested_amount)"
									persistent-hint
								/>
							</v-col>
						</v-row>
						<v-row justify="center">
							<v-col cols="8" align="center">
								<v-btn :disabled="!new_device_form_valid" color="info" @click="save_device()">Guardar</v-btn>
							</v-col>
						</v-row>
                    </v-form>
					</v-card-text>
				</v-card>
			</v-sheet>
		</v-dialog>

		<v-snackbar v-model="device_saved">
			{{ device_saved_ok }}

			<template v-slot:action="{ attrs }">
				<v-btn color="pink" text v-bind="attrs" @click="snackbar = false"> Cerrar </v-btn>
			</template>
		</v-snackbar>
	</v-container>
</template>

<script>
import { mapState } from "vuex";
import api from "../services/api";
import MakePaymentComponent from "./SubsCRUD/MakePaymentComponent.vue";
import NewSubscriptionComponent from "./NewSubscriptionComponent.vue";
import Sub_Edit from "./SubsCRUD/Sub_Edit.vue";
import moment from "moment";
export default {
	props: ["customer"],
	components: {
		MakePaymentComponent,
		NewSubscriptionComponent,
		Sub_Edit,
	},
	data() {
		return {
			device_types: [],
            new_device_form_valid: false,
			new_device: {
				serial: "",
				customer: this.customer.id,
				quantity: 1,
				type: "",
				paid_via: "",
				voucher: "",
				payment_date: moment().toISOString(true).split("T")[0],
				amount: 0,
				user: this.$store.getters.user.username,
			},
			subscription_to_edit: {},
			subscription_to_delete: {},
			menu: false,
			subscriptions_columns: [
				{ text: "Canal", value: "channel.name" },
				{ text: "Usuario", value: "panel_user" },
				{ text: "Contraseña", value: "panel_password" },
				{ text: "Fecha Inicio", value: "contract_date" },
				{ text: "Prox. Pago", value: "next_payment_date" },
				{ text: "Días", value: "days_left" },
				{ text: "Estado", value: "status" },
				{ text: "Editar", value: "edit" },
				{ text: "Borrar", value: "delete" },
			],
            devices_columns: [
                { text: "Tipo", value: "type.type"},
                { text: "Serial", value: "serial"},
                { text: "Cantidad", value: "quantity"}
            ],
			delete_dialog: false,
			make_payment_dialog: false,
			device_saved: false,
			device_saved_ok: "",
			new_device_dialog: false,
			edit: false,
			add_new: false,
			all_channels: this.$store.getters.all_channels,
			total_amount: 0,
			paid_via: "",
			voucher: "",
			payment_date: moment().toISOString(true).split("T")[0],
			new_subscriptions: [],
		};
	},
	computed: {
        ...mapState({
           payment_types: (state) => state.payment_methods 
        }),
		suggested_amount() {
			if (this.new_device.type != "") {
				const sell_price = this.device_types.find((device) => device.id === this.new_device.type).sell_price;
				return Number(sell_price) * Number(this.new_device.quantity);
			}
			return 0;
		},
		subscription_edit_contract_date: {
			get: function () {
				if (this.edit) {
					return String(new Date(this.subscription_to_edit.contract_date).toISOString().slice(0, 10));
				} else {
					return String(new Date().toISOString().slice(0, 10));
				}
			},
			set: function (newDate) {
				this.subscription_to_edit.contract_date = newDate;
			},
		},
		suggested_total_amount() {
			if (this.new_subscriptions.length > 0) {
				return this.new_subscriptions
					.map((subscription) => {
						if (subscription.channelId != "") {
							return this.all_channels.find((channel) => channel.id == subscription.channelId).cost * subscription.credits;
						} else {
							return 0;
						}
					})
					.reduce(function (acc, cost) {
						return acc + cost;
					}, 0);
			} else {
				return 0;
			}
		},
		subscription_edit_next_payment() {
			return moment(this.subscription_edit_contract_date).add(this.subscription_to_edit.credits, "months").format("YYYY-MM-DD");
		},
	},
	methods: {
		save_device() {
			api.new_device(this.new_device).then((response) => {
				if (response.status === 200) {
					this.new_device_dialog = false
                    this.$emit("get_customer");
				}
			});
		},
		get_device_types() {
			api.get_device_types().then((response) => (this.device_types = response.data));
		},
		update_subscription() {},
		save_contract_date(subscription) {
			console.log(subscription);
		},
		edit_subscription(subscription) {
			this.edit = true;
			this.subscription_to_edit = subscription;
			this.next_payment_date = subscription.next_payment_date;
		},
		confirm_delete_subscription(subscription) {
			this.subscription_to_delete = subscription;
			this.delete_dialog = true;
		},
		delete_subscription() {
			api.delete_subscription(this.subscription_to_delete).then((response) => {
				if (response.status === 200) {
					this.saved_ok();
				}
			});
		},
		get_tag(status) {
			if (status == "activo") {
				return "success";
			} else if (status === "recordar") {
				return "warning";
			} else if (status === "expirado") {
				return "error";
			}
		},
		close() {
			this.edit = false;
			this.new_device_dialog = false;
			this.subscription_to_edit = {};
			this.make_payment_dialog = false;
			console.log("closing");
			this.$emit("get_customer");
		},
		saved_ok() {
			console.log("saved_ok");
			this.new_device_dialog = false;
			this.make_payment_dialog = false;
			this.device_saved_ok = "Pago realizado con éxito";
			this.device_saved = true;
			this.$emit("get_customer");
			this.$store.dispatch("get_customers");
		},
		make_payment() {
			this.make_payment_dialog = true;
		},
		add_subscription() {
			this.add_new = true;
			this.new_subscriptions.push({
				customerId: this.customer.id,
				channelId: "",
				credits: 0,
			});
			//   }

			//   api.get_channels().then((response) => {
			//     this.all_channels = response.data;
			//   });
		},
		add_device() {
			this.get_device_types();
			this.new_device_dialog = true;
		},
		cancel_add_subscription() {
			this.new_subscriptions = [];
			this.add_new = false;
		},
		save_new_subs() {
			const new_data = {
				customerId: this.customer.id,
				user: this.$store.getters.user.username,
				total_amount: this.total_amount,
				paid_via: this.paid_via,
				voucher: this.voucher,
				payment_date: this.payment_date,
				new_subscriptions: this.new_subscriptions,
			};

			api.save_new_subscriptions(new_data).then((response) => {
				if (response.status == 200) {
					this.add_new = false;
					this.$emit("get_customer");
				}
			});
		},
	},
	mounted() {},
};
</script>

<style></style>
